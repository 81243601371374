const once = require('./once');

/* eslint-disable-next-line  no-unused-vars */
module.exports = function ({fn, rp$, window, label}) {
	if (window.__PLUGIN_READY_QUEUE) {
		window.__PLUGIN_READY_QUEUE.push(fn);
	} else {
		rp$(once(fn));
	}
};
