/* global document */

const utils = {};

utils.getXItemsFromString = function (data, separator, quantity) {
	const brandsArray = data.split(separator);
	const selectedBrands = brandsArray.slice(0, quantity);
	return {
		'selected':selectedBrands,
		'total':brandsArray.length,
	};
};

utils.productTitleEvent = function (info, cat, page = ''){
	let cat_from_eitem = (info).find('.catalog-product-oem-category a')[0];
	cat_from_eitem = cat_from_eitem ? cat_from_eitem.innerText.split(' - ')[1].trim() : '';
	let pob = (info).find('.place-order-button');
	cat = cat ? cat.innerText.trim() : ''; 
	let category = utils.getStringFromTwoStrings(cat_from_eitem, cat);
	let sale_price = pob.attr('data-sale-price'); 
	let stock = pob.attr('data-in-stock')? 'In Stock':'No Stock' ;

	const product = {
		sku_stripped: pob.attr('data-sku-stripped'),
		name: pob.attr('data-name'),
		brand: pob.attr('data-brand'),
		category: category,
		price: sale_price,
		quantity: pob.attr('data-quantity'),
		page: page || 'Unavailable',
		stock_status: stock,
	};

	return product;
};

utils.getItemsFromRpappCart = function (items){
	return items.map(item => {
		item = (item.product.product_data);
		return {
			id: item.sku_stripped,
			name: item.name,
			quantity: item.quantity,
			brand: item.brand,
			price: item.total_sale_price,
		};
	});
};

utils.getStringFromTwoStrings = function (str1, str2, default_value = null) {
	return str1.trim() || str2.trim() || default_value;
};


utils.convertNamesOfItemsForGA = function (items) {
	return items.map((item, index) => ({
		item_id: item.id || item.sku_stripped,
		item_name: item.name,
		item_brand: item.brand,
		item_category: item.category || 'Unavailable',
		price: item.price,
		quantity: item.quantityDifference || (item.quantity || 0),
		item_list_name: item.page || 'Unavailable',
		index: index + 1,
	})); 
};

utils.hashSensitiveInformation =  function (value) {
	return new Promise(function (resolve, reject) {
		if (!value) return resolve('');
		const encoder = new TextEncoder();
		const data = encoder.encode(value);
		crypto.subtle.digest('SHA-256', data)
			.then(function (hashBuffer) {
				const hashArray = Array.from(new Uint8Array(hashBuffer));
				const hashHex = hashArray.map(function (b) {
					return b.toString(16).padStart(2, '0');
				}).join('');
				resolve(hashHex);
			})
			.catch(function (error) {
				reject(error);
			});
	});
};

utils.buildCarString = function (carDetails) {
	const year = carDetails.ui_year || '';
	const make = carDetails.ui_make || '';
	const model = carDetails.ui_model || '';

	if (!year && !make && !model) {
		return 'NotSelected';
	}
	return `${year} ${make} ${model}`.trim(); 
};

utils.getCookie = function (name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift(); 
	else return '{}';
};

utils.setCookie = function (name, value, days) {
	var expires = '';
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 *1000));
		expires = '; expires=' + date.toGMTString();
	} else {
		expires = '';
	}
	document.cookie = name + '=' + value + expires + '; path=/';
};

utils.deleteCookie = function (name) {
	utils.setCookie(name,'',-1);
};



module.exports = utils;
